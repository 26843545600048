import React from "react";
import FirstLogin from "../components/FirstLogin";

function FirstLoginScreen() {
  return (
    <div>
      <FirstLogin />
    </div>
  );
}

export default FirstLoginScreen;
