import React from "react";
import TopBar from "../../components/TopBar";
import visa from "../../assets/visa.png";
import master from "../../assets/master.png";
import discover from "../../assets/discover.png";
import paypal from "../../assets/paypal.png";
import maestro from "../../assets/maestro.png";
import Button from "../../components/Button/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Paystack from "../../components/Payment/Paystack";
import Loading from "../../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { bookAppointmentAction } from "../../redux/actions/appointmentActions";
import swal from "sweetalert";
import { formatter } from "../../utils";

export default function Payment() {
    const { success, loading } = useSelector((state) => state.createPayment);
    const appt = useSelector((state) => state.bookAppontment);
    const navigate = useNavigate();
    const data = useLocation().state.data;
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem("userInfo"));
    console.log("data user", user);
    console.log("data pay", data);

    const cantEdit = () => {
        toast.warning('You cant edit this field', {
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
   
    const handleSubmit = (e) => {
        dispatch(bookAppointmentAction(data));
        // navigate("/appointment-booked", { state: { data: data } });
    }
    const commingSoon = () => {
        swal({
            text: "Payment will be available soon!, Please continue without payment.",
            title: "Coming soon.",
            icon: "warning",
            // buttons: ["Cancel", "Yes, Delete"],
        })
    }

    React.useEffect(() => {
        if (appt.error) {
            toast.error(appt?.error);
        }
        if (appt.success) {
            toast.success('Appointment booked successfully', {
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate('/appointment-booked', { state: { data: data } })
            window.location.reload()
        }
    }, [appt.success])

    return (
        <div className="h-screen ">
            <TopBar title={"Payment"} url={'/appointment-online'} />
            <div
                className="bg-primary100 m-5 rounded-lg text-white"
                style={{ borderRadius: "20px" }}
            >
                <div className="flex flex-col items-center justify-center">
                    <div className="flex justify-center items-center mt-8">
                        <div
                            className="mx-2 mt-2"
                            style={{ height: "2px", width: 30, backgroundColor: "white" }}
                        ></div>
                        <h1 className="font-medium text-2xl mt-2">Amount</h1>
                        <div
                            className="mx-2 mt-2"
                            style={{ height: "2px", width: 40, backgroundColor: "white" }}
                        ></div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5 mb-8">
                        <h1 className="font-bold text-5xl">
                            {" "}
                            <sub>&#8358; </sub>{formatter.format(data?.amount)}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="flex justify-evenly items-center mt-8">
                <div className="border-2 shadow-xl shadow-black-600">
                    <img src={visa} alt="Visa" />
                </div>
                <div className="border-2 shadow-xl shadow-black-600">
                    <img src={master} alt="Master" />
                </div>
                <div className="border-2 shadow-xl shadow-black-600">
                    <img src={discover} alt="Discover" />
                </div>
                <div className="border-2 shadow-xl shadow-black-600">
                    <img src={paypal} alt="" />
                </div>
                <div className="border-2 shadow-xl shadow-black-600">
                    <img src={maestro} alt="Maestro" />
                </div>
            </div>
            <div className="flex flex-col mt-5 mx-5">
                <small className="text-red-500" >Please, Click Pay Now to Proceed</small>
                <p className="font-semibold text-2xl">Card Details</p>

                <div className="mt-4">
                    <p>Card Holder’s Name</p>
                    <input
                        className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="  Norman Manzoor"
                        value={user?.firstname + " " + user?.lastname}
                        readOnly
                        onClick={cantEdit }
                    />

                </div>
                <div className="mt-4">
                    <p>Card Number</p>
                    <input
                        className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="  **** **** **** 2345"
                        value={"Paystack Payment"}
                        readOnly
                        onClick={cantEdit }
                    />
                </div>
                <div className="flex justify-between">
                    <div className="mt-4">
                        <p>Expiry Date</p>
                        <input
                            className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-40 py-2 rounded-md mt-3"
                            placeholder="***"
                            readOnly
                            onClick={cantEdit}
                        />
                    </div>
                    <div className="mt-4 mb-4">
                        <p>CVV</p>
                        <input
                            className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-40 py-2 rounded-md mt-3"
                            placeholder="  ***"
                            readOnly
                            onClick={cantEdit}
                        />
                    </div>
                </div>
                <div className="w-full mb-2 flex flex-col justify-center align-center" >
                    {
                        loading || appt.loading ? <Loading /> :
                            // <Paystack amount={data.amount} email={user.email.trim()} phone={user.mobile} notes={"Payment for Online Appointment"} url={"appointment-booked"} compData={data} />
                        
                            <button className="py-3 bg-primary100 rounded-md text-white text-[18px] mx-5 mt-[2rem] mb-3" onClick={commingSoon}>
                                Pay Now !
                            </button>
                    }
                    
                    {
                        loading || appt.loading ? <Loading /> :
                            <button className="py-3 rounded-md text-primary100 text-[18px] mx-5 mt-[1rem] mb-4 font-bold shadow border-2 border-primary100" onClick={handleSubmit}>
                                Continue Without Payment
                            </button>
                    } 

                </div>
            </div>
        </div>
    );
}
