import React from "react";
import Mark from "../components/Mark";

function MarkScreen() {
  return (
    <div>
      <Mark />
    </div>
  );
}

export default MarkScreen;
