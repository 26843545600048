import React from 'react';
import Paystack from './Paystack';

function PaymentModal({ showModal, handleClose, amount, onConfirm, state }) {
    const charges = 1000;
    const finalAmount = parseInt(amount) + charges;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    if (!showModal) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Confirm Payment</h2>
                <div className="mb-4">
                    <p className="text-lg text-gray-700"><strong>Amount:</strong> {formatter.format(amount ?? 0)}</p>
                    <p className="text-lg text-gray-700"><strong>Charges:</strong> {formatter.format(charges ?? 0)}</p>
                    <p className="text-lg text-gray-900 font-extrabold">Total: <strong>{formatter.format(finalAmount ?? 0)}</strong></p>
                </div>
                <div className="flex justify-between items-center mt-6">
                    <button
                        className="bg-red-500 text-white px-4 py-3 mt-3 rounded-md mr-2 hover:bg-red-600 transition duration-300"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <div className="flex-1 flex justify-end">
                        <Paystack
                            amount={finalAmount}
                            phone={state.phone}
                            email={state.email.trim()}
                            notes={state.notes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentModal;
