// test api
// export const baseUrl = "https://api.singspire.com.ng/api";

// local api
// export const baseUrl = "http://127.0.0.1:9002/api";
// export const baseUrl = "http://127.0.0.1:8000/api";
// export const PAYSTACK_PUBLIC_KEY = "pk_test_ca5483b1ebbff03f56af48ca9e744bd36d10e50c";

// production api
export const baseUrl = "https://api.crestpath.ng/api";
export const PAYSTACK_PUBLIC_KEY = "pk_live_abb4085725c2194996402355932f4fc630d8b09a";
