import {
  BOOK_APPOINTMENT_REQUEST,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAIL,
  BOOK_APPOINTMENT_RESET,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAIL,
  GET_DOCTORS_FAIL,
  GET_DOCTORS_REQUEST,
  GET_DOCTORS_RESET,
  GET_DOCTORS_SUCCESS,
  GET_CLINICS_FAIL,
  GET_CLINICS_REQUEST,
  GET_CLINICS_RESET,
  GET_CLINICS_SUCCESS,

} from "../constants/appointmentConstants.js";

import axios from "axios";
import { baseUrl } from "./baseUrl.js";

const config = {
  headers: {
    "Content-Type": "Application/json",
    "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
  },
};

const bookAppointmentAction = (request) => async (dispatch) => {
    try {
      dispatch({
        type: BOOK_APPOINTMENT_REQUEST,
      });

      let req = {
        method: 'post',
        url: `${baseUrl}/appointment/schedule?appt_date=${request.appt_date}&appt_time=${request.appt_time}&clinic_id=${request.clinic_id}&clinic_name=${request.clinic_name}&location=${request.location}&notes=${request.notes}&patient=${request.patient}&type=${request.type}&amount=${request.amount}&appt_type=${request.appt_type}&appt_mode=${request.appt_mode}&appt_url=${request.appt_url}`,
        headers: {
          "Content-Type": "Application/json",
          "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
        },
      }
      // return;
      const data = await axios.request(req);

      dispatch({
        type: BOOK_APPOINTMENT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      let message =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: BOOK_APPOINTMENT_FAIL,
        payload: message,
      });
    }
  };

const getAppointmentAction = (patient) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPOINTMENT_REQUEST,
    });
    const { data } = await axios.get(
      `${baseUrl}/appointments?patient=${patient}`,
      config
    );

    dispatch({
      type: GET_APPOINTMENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    let message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: GET_APPOINTMENT_FAIL,
      payload: message,
    });
  }
};

const getDoctorsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCTORS_REQUEST,
    });

    const { data } = await axios.get(
      `${baseUrl}/doctors`,
      config
    );

    dispatch({
      type: GET_DOCTORS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    let message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: GET_DOCTORS_FAIL,
      payload: message,
    });
  }
};

const getClinicsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLINICS_REQUEST,
    });

    const { data } = await axios.get(
      `${baseUrl}/clinics`,
      config
    );

    dispatch({
      type: GET_CLINICS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    let message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: GET_CLINICS_FAIL,
      payload: message,
    });
  }
};




export { bookAppointmentAction, getAppointmentAction, getDoctorsAction, getClinicsAction };
