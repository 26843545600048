import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaCalendarAlt, FaUsers } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { getRecentPayments } from "../redux/actions/payment";
import { useDispatch, useSelector } from 'react-redux';
import swal from "sweetalert";

export default function BottomNav({ color, color1, color2, color3 }) {
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem('userInfo'));
  const { loading, success, data } = useSelector(state => state.getRecentPayments);

  // useEffect(() => {
  //   dispatch(getRecentPayments(user && user.id ? user.id : ''))
  //   const todayTransactions = data?.recent_trans?.filter(transaction =>
  //     new Date(transaction.created).toDateString() === new Date().toDateString()
  //   );
  //   const recentTransaction = todayTransactions?.find(transaction => transaction.paid === 'yes');

  //   if (recentTransaction) {
  //     swal({
  //       text: "Your Payment Receipt is ready!",
  //       title: "Payment Receipt",
  //       icon: "success",
  //     });
  //   }

  // }, []);
  function playAudio() {
    new Audio('/audio/beep.mp3').play();
  }

  useEffect(() => {
    const maxPopups = 3;
    const currentDate = new Date().toDateString();
    const popupCount = JSON.parse(localStorage.getItem('popupCount')) || {};

    if (!popupCount[currentDate]) {
      popupCount[currentDate] = 0;
    }

    if (popupCount[currentDate] < maxPopups) {
      dispatch(getRecentPayments(user && user.id ? user.id : ''));

      const todayTransactions = data?.recent_trans?.filter(transaction =>
        new Date(transaction.created).toDateString() === currentDate
      );

      const recentTransaction = todayTransactions?.find(transaction => transaction.paid === 'yes');

      if (recentTransaction) {
        swal({
          text: "Your Payment Receipt is ready!",
          title: "Payment Receipt",
          icon: "success",
          buttons: {
            view: {
              text: "View",
              value: "view",
              className: "swal-button--view"
            },
            close: {
              text: "Close",
              value: "close",
              className: "swal-button--close"
            }
          }
        }).then((value) => {
          if (value === "view") {
            window.location.href = "https://api.crestpath.ng/payment/receipt/" + recentTransaction.session_id;
          }
        });
        playAudio();
        // Increment the popup count for the day
        popupCount[currentDate] += 1;
        localStorage.setItem('popupCount', JSON.stringify(popupCount));
      }

    }
  }, []);

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white">
      <div className="flex justify-between items-center mx-4">
        <Link to="/homedashboard">
          <div className={color ? color + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <AiFillHome className="text-4xl" />
            <p>Home</p>
          </div>
        </Link>
        <Link to="/appointment">
          <div className={color1 ? color1 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <FaCalendarAlt className="text-4xl" />
            <p>Appointment</p>
          </div>
        </Link>
        <Link to="/queue-barcode">
          <div className={color2 ? color2 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <FaUsers className="text-4xl" />
            <p>Queue</p>
          </div>
        </Link>
        <Link to="/payment">
          <div className={color3 ? color3 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <MdPayment className="text-4xl" />
            <p>Transactions</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
