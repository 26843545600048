import React from "react";
import { ImCheckmark } from "react-icons/im";
import { Link } from "react-router-dom";

function PasswordCorrect() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-screen md:h-[638px] relative rounded-md  shadow-xl">
        <div className="flex justify-center items-center mt-[3rem]">
          <div className="w-[120px] h-[120px] bg-primary100 flex justify-center items-center rounded-full">
            <ImCheckmark className="text-white text-[3rem]" />
          </div>
        </div>
        <p className="text-[2rem] flex justify-center items-center mt-[2rem]">
          Password Correct!
        </p>
        <p className="text-ash100 flex justify-center items-center mt-[3rem] px-10">
          Your password has been created. you can now always login to your
          account with this password
        </p>
        <Link to="/homedashboard">
          {" "}
          <div className="flex justify-center items-center">
            <button className=" w-full  py-2 bg-primary100 rounded-md text-white text-[18px] mx-5 mt-[2rem]">
              Continue
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default PasswordCorrect;
