import React from "react";
import TopBar from "../TopBar";
import { FaRegBell } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";

export default function Queue() {
    return (
        <div className="h-screen ">
            <TopBar title={"Queue"} url={'/queue-wait'} />
            <div className="flex flex-col items-center justify-center mt-16">
                <p className="text-xl">Now attending to patient number</p>
                <div>
                    <h1 className="font-bold text-6xl mt-8">12</h1>
                </div>
                <div className="flex items-center justify-center mt-8">
                    <button className="flex justify-center items-center py-2 w-40 px-2 bg-white rounded-md text-primary100 text-[18px] outline outline-primary100 outline-1 mt-3 mx-2">
                        <TfiReload className="mr-3" />
                        Again
                    </button>
                    <button className="flex justify-center items-center py-2 w-40 px-2 rounded-md text-white text-[18px] bg-primary100 outline-1 mt-3 mx-2">
                        <FaRegBell className="mr-3" />
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}
