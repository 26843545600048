import {
  BOOK_APPOINTMENT_REQUEST,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAIL,
  BOOK_APPOINTMENT_RESET,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAIL,
  GET_APPOINTMENT_RESET,
  GET_DOCTORS_FAIL,
  GET_DOCTORS_REQUEST,
  GET_DOCTORS_RESET,
  GET_DOCTORS_SUCCESS,
  GET_CLINICS_FAIL,
  GET_CLINICS_REQUEST,
  GET_CLINICS_RESET,
  GET_CLINICS_SUCCESS,
} from "../constants/appointmentConstants.js";

const bookAppontmentReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOK_APPOINTMENT_REQUEST:
      return { loading: true };
    case BOOK_APPOINTMENT_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case BOOK_APPOINTMENT_FAIL:
      return { loading: false, success: false, error: action.payload };
    case BOOK_APPOINTMENT_RESET:
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

const getAppointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_REQUEST:
      return { loading: true };
    case GET_APPOINTMENT_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case GET_APPOINTMENT_FAIL:
      return { loading: false, success: false, error: action.payload };
    case GET_APPOINTMENT_RESET:
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

const getDoctorsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DOCTORS_REQUEST:
      return { loading: true };
    case GET_DOCTORS_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case GET_DOCTORS_FAIL:
      return { loading: false, success: false, error: action.payload };
    case GET_DOCTORS_RESET:
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

const getClinicsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLINICS_REQUEST:
      return { loading: true };
    case GET_CLINICS_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case GET_CLINICS_FAIL:
      return { loading: false, success: false, error: action.payload };
    case GET_CLINICS_RESET:
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};



export { bookAppontmentReducer, getAppointmentReducer, getDoctorsReducer, getClinicsReducer };
