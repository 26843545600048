import React, { useEffect, useState } from "react";
import AppointmentCard from "../../components/Appointment/AppointmentCard";
import Button from "../../components/Button/Button";
import TopBar from "../../components/TopBar";
import doctorPics from "../../assets/clinic.jpg";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "../../components/BottomNav";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentAction } from "../../redux/actions/appointmentActions";
import Loading from "../../components/Loading";
import { check_token } from "../../utils";

export default function UpcomingAppointment() {
    const store = useSelector(state => state.getAppointmentAction);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    const data = store.data;

    useEffect(() => {
        check_token();
        setUpcomingAppointments(data?.upcoming);
        if (!store.success) {
            dispatch(getAppointmentAction(JSON.parse(localStorage.getItem("userInfo")).id));
        }

    }, [upcomingAppointments, data?.upcoming])

    return (
        <div className="h-full">
            <TopBar title={"Appointment"} url={'/homedashboard'} />
            <div className="mx-5 mt-5 flex justify-between items-center">
                <Link to={'/appointment'}>
                    <Button
                        width={"40"}
                        text={"Upcoming"}
                        textColor={"text-white"}
                        bg={"bg-primary100"}
                    />
                </Link>
                <Link to={'/appointment-past'} >
                    <Button width={"40"} text={"Past"} />
                </Link>
            </div>
            <div className="mb-16">
                {
                    store?.loading ? <Loading/>:
                 upcomingAppointments?.length > 0 ? upcomingAppointments.map((item, index) => {
                    return (
                        <div key={index}>
                            <AppointmentCard
                                pics={doctorPics}
                                name={item.clinic_name ? item.clinic_name : "Clinic"}
                                post={"Clinic"}
                                date={item.appt_date}
                                time={item.appt_time}
                                mode={item.appt_type}
                                key={index}
                            />
                        </div>
                    )
                }) : <div className=" flex justify-center items-center">
                        <p className="text-primary100 font-bold mt-5">No Upcoming appointments</p>
                </div>}

            </div>

            <BottomNav color1={'text-primary100'} />
        </div>
    );
}
