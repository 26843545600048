import React from "react";
import OTP from "../components/OTP";

function OTPscreen() {
  return (
    <div>
      <OTP />
    </div>
  );
}

export default OTPscreen;
