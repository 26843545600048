import React from "react";
import { ImCheckmark } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Mark() {
  const navigate = useNavigate();
  const data = useLocation().state;
  const handleClick = () => {
    navigate("/otp", { state: data });
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-screen md:h-[638px] relative rounded-md  shadow-xl">
        <div className="flex justify-center items-center mt-[3rem]">
          <div className="w-[120px] h-[120px] bg-primary100 flex justify-center items-center rounded-full">
            <ImCheckmark className="text-white text-[3rem]" />
          </div>
        </div>
        <p className="text-[2rem] flex justify-center items-center mt-[2rem]">
          File Number Correct!
        </p>
        <p className="text-ash100 flex justify-center items-center mt-[3rem] px-10">
          To verify your account, we've sent a one time password (OTP) to your
          phone number.
        </p>

        <div className="flex justify-center items-center">
          <button
            onClick={handleClick}
            className=" w-full  py-2 bg-primary100 rounded-md text-white text-[18px] mx-5 mt-[2rem]">
            Continue
          </button>
        </div>

      </div>
    </div>
  );
}

export default Mark;
