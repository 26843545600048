import React, { useEffect, useState } from "react";
import AppointmentCard from "../../components/Appointment/AppointmentCard";
import Button from "../../components/Button/Button";
import TopBar from "../../components/TopBar";
import doctorPics from "../../assets/clinic.jpg";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "../../components/BottomNav";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { check_token } from "../../utils";

export default function PastAppointment() {
    const store = useSelector(state => state.getAppointmentAction);
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = store.data;
    const [pastApointments, setPastAppointments] = useState([]);

    useEffect(() => {
      check_token()
        setPastAppointments(data?.past);
    }, [])
    return (
        <div className="h-full">
            <TopBar title={"Appointment"} url={'/homedashboard'} />
            <div className="mx-5 mt-5 flex justify-between items-center">
                <Link to={'/appointment'}>
                    <Button width={"40"} text={"Upcoming"} />
                </Link>
                <Link to={'/appointment-past'} >
                    <Button
                        width={"40"}
                        text={"Past"}
                        textColor={"text-white"}
                        bg={"bg-primary100"}
                    /></Link>
            </div>
            { store?.loading? <Loading/>:
                pastApointments?.length > 0 ? pastApointments.map((item, index) => {
                    return (
                        <div key={index}>

                            <AppointmentCard
                                pics={doctorPics}
                                name={item.clinic_name ? item.clinic_name : "Clinic"}
                                post={"Clinic"}
                                date={item.appt_date}
                                time={item.appt_time}
                                mode={item.appt_type}
                                key={index}
                            />

                        </div>
                    )
                })

                    : <div className=" flex justify-center items-center">
                        <p className="text-primary100 font-bold mt-5">No past appointment</p>
                    </div>}
            <BottomNav color1={'text-primary100'} />
        </div>
    );
}
