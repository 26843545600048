import React from "react";
import PasswordCorrect from "../components/PasswordCorrect";

function PasswordCorrectScreen() {
  return (
    <div>
      <PasswordCorrect />
    </div>
  );
}

export default PasswordCorrectScreen;
