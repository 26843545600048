import React from 'react'
import TopBar from '../components/TopBar';
import Scanner3 from '../components/queue/Scanner3';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

export default function QrLogin() {
  const [start, setStart] = React.useState(false)
  const [scanResult, setScanResult] = React.useState('');
  const store = useSelector((state) => state.loginUser);
  console.log('store', store)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startStop = () => {
    setStart(!start)
  }
  const handleScanResult = (data) => {
    setScanResult(data);
  };

  useEffect(() => {
    if (store?.success) {
      toast.success('Login Successful');
      navigate('/homedashboard');
      window.location.reload();
      setStart(!start);
    } else if (store?.error) {
      toast.error(store.error.message);
    }
    if (store?.error?.todo == 'verify_otp') {
      swal({
        text: "You are not registered or you need to verify your phone number.",
        title: "Please register first !",
        icon: "warning",
      })
    }
  }, [store?.success, store?.error]);


  return (
      <div className='h-screen'>
      <TopBar title={'QR COde Scanner'} url={-1} />
      
      <div className='flex justify-center h-full'>
        <div className='bg-white rounded-lg'>
          {/* <div className='text-center shadow-md w-full'>
            <h1 className='text-2xl font-bold '>Scan QR Code</h1>
            <p className='mt-2'>
              Scan the QR code to login/continue.
              <br />
              <br /> 
            </p>
          </div> */}

          <div className="flex flex-col items-center justify-center mt-10">
            <p className='m-3' >You must be registered to log in with a QR code</p>
            <button onClick={startStop} className="bg-primary100 text-white p-2 rounded-md w-3/6 mb-4">
              {start ? "Stop" : "Start"}
              {start ? <i className="fa fa-stop ml-2"></i> : <i className="fa fa-play ml-2"></i>}
            </button>
            {
              start ?
                <Scanner3
                  setStart={setStart}
                  start={start}
                  type={'login'}
                
                /> :
                <img className="mt-2" src="./barcode.png" alt="" />
            }
          </div>
        </div>
      </div>
    </div>
  )
}
