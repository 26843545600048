import { CHECK_APPOINTMENT_FAIL, CHECK_APPOINTMENT_REQUEST, CHECK_APPOINTMENT_SUCCESS } from "../constants/checkAppointment";

const checkAppointment = (state = {}, action) => {
    switch (action.type) {
        case CHECK_APPOINTMENT_REQUEST:
            return { loading: true };
        case CHECK_APPOINTMENT_SUCCESS:
            return { loading: false, success: true, data: action.payload };
        case CHECK_APPOINTMENT_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

export { checkAppointment }