import React, { useEffect } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';

export default function TopBar({ title, url }) {
    return (
        <div className="flex justify-between w-screen mt-5 shadow border-1 pb-2">
            {/* <div className="flex-none w-14 "> */}
            <div>
                <Link to={url}>
                    <IoIosArrowBack className="ml-2 text-4xl text-primary100" /></Link>
            </div>
            <h1 className='-ml-12 font-bold text-2xl text-primary100'>{title}</h1>
            <div></div>
        </div>
    )
}
