import React, { useState } from 'react';
import QrReader from 'modern-react-qr-reader';
import axios from 'axios';
import { baseUrl } from '../../redux/actions/baseUrl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserAction } from '../../redux/actions/userActions';
import Loading from '../Loading';
import { useEffect } from 'react';

const Scanner3 = ({ setStart, start, onScanResult, type }) => {
    const [delay] = useState(100);
    const [facingMode, setFacingMode] = useState('environment');
    const [result, setResult] = useState('No result');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
   
    const navigate = useNavigate();

    const verify_qr = async (data, type) => {
        //   make an API call to verify barcode data
        try {
            // let res = await axios.get(`${baseUrl}/check_barcode?qr_code=${data}&type=${type}`);
            const res = await axios.get(`${baseUrl}/check_barcode?qr_code=${encodeURIComponent(data)}&type=${encodeURIComponent(type)}`);
            console.log('res', res)
            if (res.data.status === 'success') {
                console.log('data', res.data)
                // setLoading(true);
                dispatch(loginUserAction(res.data.patient.system_id, res.data.patient.firstname, res.data.patient.id));
            }
        } catch (err) {
            setLoading(false);
            toast.error('Invalid QR Code')
            console.log('err', err)
            return false;
        }
    }

    // const handleScan = (data) => {
    //     console.log('data', data)
    //     setResult(data);
    //     if (type === 'login') {
    //         if (data) {
    //             setStart(!start)
    //             // onScanResult(data);
    //             let check = verify_qr(data, 'login');
    //             setLoading(true);
    //             if (store.success) {
    //                 toast.success('Login Successful')
    //                 setLoading(false);
    //                 navigate('/homedashboard')
    //             }
    //             if (store.error) {
    //                 setLoading(false);
    //                 toast.error('Something went wrong')
    //             }
    //         }
    //     } else {
    //         if (data) {
    //             setStart(!start)
    //             onScanResult(data);
    //             window.open(data, '_blank');
    //         }
    //     }
            
    // };

    const handleScan = async (data) => {
        console.log('data', data);
        let trimmed_data = data?.replace(/^(https?:\/\/)/i, '');
        setResult(trimmed_data);
        if (type === 'login') {
            if (data) {
                try {
                    setLoading(true);
                    setStart(!start);
                    toast.warning('Please wait while we verify your QR Code...', {
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    await verify_qr(trimmed_data, 'login');
                   
                } catch (err) {
                    setLoading(false);
                    toast.error('Invalid QR Code');
                    console.log('err', err);
                }
            }
        } else if (type === 'register') {
            if (data) {
                onScanResult(trimmed_data);
                    try {
                        setLoading(true);
                        setStart(!start);
                        // await verify_qr(data, 'register');
                        return;
                       
                    } catch (err) {
                        setLoading(false);
                        toast.error('Invalid QR Code');
                        setStart(!start);
                        console.log('err', err);
                        return;
                    }
                }
            } else {
            if (data) {
                console.log('window')
                setStart(!start);
                onScanResult(data);
            }
        }
    };

    const handleError = (err) => {
        console.log('error', err)
        console.error(err);
    };

    const previewStyle = {
        height: 240,
        width: 320,
    };

    useEffect(() => {
       
    
    }, [loading]);

    return (
        <>
            {
                loading ? 
                    <Loading /> :
                    <div className="flex justify-center items-center flex-col">
                        <select className='p-2 rounded w-3/6 text-center' onChange={
                            (e) => {
                                console.log('setFacingMode', e.target.value)
                                setFacingMode(e.target.value)
                            }
                        } >
                            <option value="environment">Back</option>
                            <option value="user">Front</option>
                        </select>
                        <QrReader
                            delay={delay}
                            style={previewStyle}
                            onError={handleError}
                            onScan={handleScan}
                            facingMode={facingMode}
                        />
                    </div>
            }
      
        </>
    );
};

export default Scanner3;
