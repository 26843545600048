import { CREATE_PAYMENT_FAIL, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_REQUEST, GET_RECENT_PAYMENT_REQUEST, GET_RECENT_PAYMENT_SUCCESS, GET_RECENT_PAYMENT_FAIL } from "../constants/payment";


const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const createPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PAYMENT_REQUEST:
            return { ...state, loading: true };
        case CREATE_PAYMENT_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case CREATE_PAYMENT_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

const getRecentPaymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECENT_PAYMENT_REQUEST:
            return { ...state, loading: true };
        case GET_RECENT_PAYMENT_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_RECENT_PAYMENT_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export { createPaymentReducer, getRecentPaymentsReducer }
