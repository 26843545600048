import React from 'react'
import Welcome from '../components/Welcome.js'

function WelcomeScreen() {
  return (
    <div>
      <Welcome />
    </div>
  )
}

export default WelcomeScreen