import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function QrButton({ onClick, text }) {
    const navigate = useNavigate();
  return (
    //   <div>QrButton</div>
       <button
          onClick={onClick}
      className="w-5/6 py-1 rounded-md text-primary100 text-[18px] border-2 border-primary100 shadow-xl shadow-black-600 "
              >
      {text ? text : 'Scan QR'}
              </button>
  )
}
