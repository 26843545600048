import axios from "axios";
import logo from "./assets/logo.png";
import { async } from "q";
import { baseUrl } from "./redux/actions/baseUrl";

// const pushNotification = (body) => {
//     if (Notification.permission !== "granted") {
//         Notification.requestPermission();
//     } else {
//         const notification = Notification.showNotification("Misas", {
//             body: body,
//             icon: logo,
//         });
//         notification.onclick = () => {
//             window.location.href = "/notification";
//         };
//     }
// };

function pushNotification(body) {
    Notification.requestPermission((result) => {
        if (result === "granted") {
            const notification = navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification("Misas", {
                    body: body,
                    icon: logo,
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    tag: "vibration-sample",
                });
                
                notification.onclick = () => {
                window.location.href = "/notification";
                };

            });
        }
    });
}

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    currency: 'NGN',
});

async function check_token() {
    if (localStorage.getItem('btn_mi_sas') == null || localStorage.getItem('btn_mi_sas') == undefined ) {
        window.location.href = "/login";
        localStorage.clear();
        return false;
    }

    if (localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == undefined || !localStorage.getItem('userInfo') ) {
        window.location.href = "/login";
        localStorage.clear();
        return false;
    }

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
        },
    };

    try {
        const response = await axios.get(`${baseUrl}/check_token`, config);
        const { valid } = response.data;

        if (!valid) {
            window.location.href = "/login";
            localStorage.clear();
            return false;
        }

        return true;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = "/login";
            localStorage.clear();
            return false;
        }
        console.error("An error occurred while checking the token:", error);
        return false;
    }
}


export { pushNotification, check_token, formatter };