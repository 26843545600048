import React from 'react'
import TopBar from '../../components/TopBar'
import { useEffect } from 'react';
import { baseUrl } from '../../redux/actions/baseUrl';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';

export default function LabResult() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const [labFiles, setLabFiles] = useState([]);
    const get_lab_result_files = async (user) => {
        console.log('user', user, user.id)
        try {
            const res = await axios.get(`${baseUrl}/lab_result_files?patient=${user}`, {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
                },
            });
            console.log('lab', res)
            if (res.data.status === 'success') {
                setLabFiles(res.data?.lab_results)
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    useEffect(() => {
        get_lab_result_files(user.id);
    }, []);
  return (
      <div className='h-full'>
          <TopBar title={"Lab Results"} url={-1} />

          <div className="mb-8 mt-4">
              <div className='flex flex-col justify-center items-center'>
                  <p className='text-primary100 m-4'>Your recent lab result files will appear here and you can view them.</p>

                  <div>
                      {
                          labFiles?.length > 0 ? 
                              labFiles?.map((file,i) => {
                                return(
                                  <div key={i} className='shadow border-2 flex justify-between items-center w-full px-4 py-2 rounded'>
                                        <p className="text-black font-bold">{file?.created?.substring(0, 16)}...</p>
                                        {file.image.length > 18 ? (
                                            <p className="text-black mx-2">{file?.image.substring(0, 18)}...</p>
                                        ) : (
                                                <p className="text-black mx-2">{file?.image}</p>
                                        )}
                                        <a href={'https://api.singspire.com.ng/LabResults/Promise/'+file.image}>
                                          <AiFillEye size={40} color='#087F4C' />
                                        </a>
                                        
                                      </div>
                                )
                              }) : ' No data found'
                      }
                 </div>


              </div>
          </div>

          {/* <BottomNav color1={'text-primary100'} /> */}
      </div>
  )
}
