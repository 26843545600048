import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  GET_OTP_REQUEST,
  GET_OTP_SUCCESS,
  GET_OTP_FAIL,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_FAIL,
} from "../constants/userConstants.js";

import axios from "axios";
import { baseUrl } from "./baseUrl.js";

// const logout = () => (dispatch) => {
//   localStorage.removeItem("userInfo");
//   dispatch({ type: LOGOUT_USER });
// };


const createUserAction = (filenumber) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_USER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "Application/json",
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/get_patient?file_number=${filenumber}`,
      config

    );

    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    let message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: CREATE_USER_FAIL,
      payload: message,
      todo: err.data?.todo == 'verify_otp' ? 'verify_otp' : '',
    });
  }
};

const getOTp = (file_number, phone_number) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OTP_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "Application/json",
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/send_otp?file_number=${file_number}&phone_number=${phone_number}`,
      config
    );

    dispatch({
      type: GET_OTP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    let message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: GET_OTP_FAIL,
      payload: message,
    });
  }
};

const verifyOtp = (file_number, phone_number, otp) => async (dispatch) => {
  try {
    dispatch({
      type: VERIFY_OTP,
    });

    const config = {
      headers: {
        "Content-Type": "Application/json",
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/verify_otp?file_number=${file_number}&phone_number=${phone_number}&otp=${otp}`,
      config
    );

    dispatch({
      type: VERIFY_OTP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    let message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: VERIFY_OTP_FAIL,
      payload: message,
    });
  }
};

const loginUserAction =
  (file_number, password,patient_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOGIN_USER_REQUEST,
      });

      const config = {
        "Content-Type": "Application/json",
      };

      const request_data = {
        file_number,
        password,
        patient_id
      };

      const { data } = await axios.post(
        `${baseUrl}/login`, request_data, config
      );

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: data,
      });

      console.log('dataaaa', data);
      localStorage.setItem("userInfo", JSON.stringify(data.patient));
      localStorage.setItem("hospital_name", JSON.stringify(data.hospital_name));
      localStorage.setItem("hospital_phone", JSON.stringify(data.hospital_phone));
      localStorage.setItem("btn_mi_sas", JSON.stringify(btoa(data.misas)));
      localStorage.setItem("type", JSON.stringify(data.type));
      localStorage.setItem("consultation_audio", JSON.stringify(data.consultation_audio));
      localStorage.setItem("consultation_video", JSON.stringify(data.consultation_video));
    } catch (err) {
      let message =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      let todo =
        err.response && err.response.data.todo ? err.response.data.todo : null;
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: { message: message, todo: todo },
      });
    }
  };


const logoutUserAction =
  (file_number, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOGOUT_USER_REQUEST,
      });
      
      const config = {
        headers: {
          "Content-Type": "Application/json",
          "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
        },
      };
      const { data } = await axios.get(
        `${baseUrl}/logout`, config
      );

      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: data,
      });
      localStorage.clear();
    } catch (err) {
      let message =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      let todo =
        err.response && err.response.data.todo ? err.response.data.todo : null;
      dispatch({
        type: LOGOUT_USER_FAIL,
        payload: { message: message },
      });
    }
  };


export { createUserAction, loginUserAction, getOTp, verifyOtp, logoutUserAction };
