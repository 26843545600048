import React from "react";
import { BsFillBellFill } from "react-icons/bs";

export default function NotificationRow({ message, textColor }) {
  return (
    <div className="flex mt-5">
      <div className="flex w-1/6 rounded-full bg-green-100 flex items-center justify-center p-4">
        <BsFillBellFill className="text-primary100 text-2xl" />
      </div>
      <div className={"ml-2 w-5/6 flex items-center " + textColor}>
        < p > {message}</p>
      </div >
    </div >
  );
}
