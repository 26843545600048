import React, { useEffect, useState } from 'react';
import { ChatList, Button } from 'react-chat-elements';
import TopBar from '../../components/TopBar';
import { useNavigate } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import Loading from '../../components/Loading';
import { check_token } from '../../utils';
import { BsFillChatDotsFill } from 'react-icons/bs';

export default function Chats() {
    const navigate = useNavigate();
    const [chatData, setChatData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const sender_id = JSON.parse(localStorage.getItem('userInfo'))?.id.toString();
    // const receiver = JSON.parse(localStorage.getItem('hospital_name'));

      const type = JSON.parse(localStorage.getItem('type'));
    // const receiver = type == 'Hospital' ? localStorage.getItem('chat_receiver').toString() :JSON.parse(localStorage.getItem('hospital_name'));
    const sender = type == 'Hospital' ? JSON.parse(localStorage.getItem('hospital_name')) : JSON.parse(localStorage.getItem('userInfo'))?.firstname;
    const sender_id = type == 'Hospital' ? JSON.parse(localStorage.getItem('hospital_name')) : JSON.parse(localStorage.getItem('userInfo'))?.id.toString();
    const receiver = type == 'Hospital' ? JSON.parse(localStorage.getItem('hospital_name')) : JSON.parse(localStorage.getItem('userInfo'))?.id.toString();

    const handleChatItemClick = (chat) => {
        console.log('chat', chat);
        localStorage.setItem('chat_receiver', chat.sender);
        navigate('/chat');
    };

    useEffect(() => {
        check_token();
        const unsubscribe = firestore
            .collection('messages')
            .where('receiver', '==', receiver)
            .orderBy('timestamp', 'desc')
            .limit(500)
            .onSnapshot((snapshot) => {
                const distinctChatData = [];
                const senderSet = new Set([sender_id]); // Initialize with sender ID
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    const sender = data.sender;
                    if (!senderSet.has(`${sender}-${receiver}`) && sender !== sender_id) {
                        senderSet.add(`${sender}-${receiver}`);
                        const chatItem = {
                            avatar: 'https://misas.com.ng/assets/images/logos/misaslogo.png',
                            alt: 'Misas Logo',
                            title: sender === sender_id ? 'You' : data.sender_name,
                            subtitle: data.text,
                            date: data.timestamp.toDate(),
                            unread: calculateUnreadCount(sender, receiver), // Calculate unread count
                            ...data
                        };
                        distinctChatData.push(chatItem);
                    }
                });
                setChatData(distinctChatData);
                setIsLoading(false);
                console.log('distinctChatData',distinctChatData);
            });

        return () => unsubscribe();
    }, [sender_id, receiver]);

    const calculateUnreadCount = (sender, receiver) => {
        
        return '0'; 
    };


    return (
        <div className="h-screen">
            <div>
                <TopBar url={'/homedashboard'} title={'Chats'} />
            </div>
            <div className="chat-list mt-4">
                <div>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            {chatData.length > 0 ? (
                                <ChatList
                                    dataSource={chatData}
                                    onClick={(chat) => handleChatItemClick(chat)} // Pass the chat object as a parameter
                                />
                            ) : (
                                        <div className='w-full flex justify-center items-center mt-4 h-screen'>
                                            {
                                                JSON.parse(localStorage.getItem('type')) != 'Hospital' ?
                                                    <Button
                                                        text={'Chat' + ' ' + JSON.parse(localStorage.getItem('hospital_name'))}
                                                        onClick={() => navigate('/chat')}
                                                        backgroundColor="#087F4C"
                                                        className='p-4 font-bold text-white text-md rounded-md w-6/6]'
                                                        icon={
                                                            <BsFillChatDotsFill size={20} />
                                                        }
                                                    />
                                                    :
                                                    <p className='text-center font-bold text-xl' >Messages from patients will appear here. No messages yet</p>
                                           }
                                         </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

