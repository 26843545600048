import React from "react";
import { BsBellFill } from "react-icons/bs";

export default function NoNotification({ name }) {
  return (
    <div className="flex flex-col items-center justify-center h-5/6">
      <BsBellFill className="text-9xl text-gray-400" />
      <p className="mt-12">
        <b> Hello {name}</b> {"your have no notifications yet!"}
      </p>
    </div>
  );
}
