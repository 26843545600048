import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function ChatUs() {
    const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };
    

    return (
        <div className="ChatUs">
            <TawkMessengerReact
                propertyId="66d7e2bfea492f34bc0d70d6"
                widgetId="1i6tkrfsl"
                useRef={tawkMessengerRef}
            />
        </div>
    );
}
export default ChatUs;